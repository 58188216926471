import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { 
  Button, Typography, Box, FormControl, InputLabel, MenuItem, Select,
  Table, TableBody, TableCell, TableHead, TableRow, Divider, Alert, AlertTitle
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { DialogActions } from '@mui/material';

function FilePreview(props) {
  return (
    <Dialog fullScreen={false} open={props.open} fullWidth maxWidth="md">
      <Grid container p={2}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow key={0}>
                {Object.entries(props.mappings).map(([k, v], cellIdx) => (
                  <TableCell key={cellIdx} sx={{ verticalAlign: "top" }}>
                    <Typography variant="custom" sx={{ mt: 2, mb: 2, fontWeight: "bold" }}>
                      {k}
                    </Typography>
                    <br />
                    <Typography variant="custom" sx={{ mt: 2, mb: 2, fontWeight: "light" }}>
                      ({v})
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {props.data.slice(0, 5).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.entries(props.mappings).map(([k, v], cellIdx) => {
                    return (
                      <TableCell key={cellIdx}>{row[v]}</TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </Grid>

        <Grid item xs={12}>
          <DialogActions width="100%">
            <Grid container mt={3}>

              <Grid item xs={6} pr={1}>
                <Button
                  onClick={props.onCancel}
                  variant="outlined"
                  color="primary"
                  sx={{ width: "100%" }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={6} pl={1}>
                <Button
                  onClick={props.onAccept}
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                >
                  Accept
                </Button>
              </Grid>

            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  )
}



export function FilePopup(props) {

  const [columnMap, setColumnMap] = useState({});
  const [checked, setChecked] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [error, setError] = useState(null);

  console.log(columnMap)

  useEffect(() => {
    setColumnMap({});
    setChecked(false);
    setPreviewOpen(false);
  }, [props.open]);

  useEffect(() => {
    console.log(columnMap)
    props.setParentFeatureMap(columnMap)
  }, [columnMap])

  const handlePreviewCancel = () => {
    setPreviewOpen(false);
    setChecked(false);
  }

  const validateMapping = () => {
    if (Object.keys(columnMap).length !== props.features.length) {
      setError("Please map all required features to columns in your data.");
      return false;
    }
    return true;
  };

  const handlePreviewAccept = () => {
    if (validateMapping()) {
      setPreviewOpen(false);
      setChecked(true);
    }
  };

  return (
    <Dialog fullScreen={false} onClose={props.onClose} open={props.open} fullWidth maxWidth="sm">
      <Grid container p={2}>
        {error && (
          <Grid item xs={12} mb={2}>
            <Alert severity="error" onClose={() => setError(null)}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} mb={1} p={1}>
          <Typography variant="h5" fontWeight={"bold"}>
            Please map the columns of your data to the features
          </Typography>
          <br />
          <Typography variant="subtitle1" fontWeight={"light"} mb={2}>
            Uploading "{props.filename}"
          </Typography>
          <Divider orientation='horizontal' />
        </Grid>

        <FilePreview
          open={previewOpen}
          onCancel={() => handlePreviewCancel()}
          onAccept={() => handlePreviewAccept()}
          data={props.data}
          mappings={columnMap}
        />

        {props.features.map((feature) => {
          return (
            <Grid item xs={12}>
              <Grid container p={1}>

                <Grid item xs={6}>
                  <Box sx={{ height: "100%", alignContent: "center" }}>
                    <Typography variant="h7" fontWeight={"bold"}>
                      {feature}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="select-column-label">Select Column</InputLabel>
                      <Select
                        labelId="select-column-label"
                        label="Select Column"
                        onChange={(e) => setColumnMap(prev => { return { ...prev, [feature]: e.target.value } })}
                        sx={{ width: '100%' }}
                      >
                        {props.columns.map((column) => {
                          return <MenuItem value={column}>{column}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          )
        })}

        <Grid item xs={12}>
          <DialogActions width="100%">
            <Grid container mt={3}>

              <Grid item xs={6} pr={1}>
                <Button
                  onClick={props.onCancel}
                  variant="outlined"
                  color="primary"
                  sx={{ width: "100%" }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={6} pl={1}>
                <Button
                  onClick={() => {
                    if (validateMapping()) {
                      props.onClose();
                      // Remove this line:
                      // props.setSuccessAlert("CSV upload initiated successfully");
                    }
                  }}
                  variant="contained"
                  disabled={!(Object.keys(columnMap).length === props.features.length)}
                  color="primary"
                  sx={{ width: "100%" }}
                >
                  Upload for {props.cost} ₹
                </Button>
              </Grid>

            </Grid>
          </DialogActions>
        </Grid>
      </Grid>

    </Dialog >
  )
}
