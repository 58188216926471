import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';

const content = {
  en: {
    sectionTitle: 'Product features',
    items: [
      {
        icon: <DashboardIcon />,
        title: 'Dashboard',
        description:
          'Unlock powerful insights with our enhanced Dashboard, featuring tools like stacked bar charts, NPS moving averages, and detailed comment analysis for clear, actionable decisions.',
        imageLight: '../images/dashboard_landing.png',
        imageDark: '/static/images/templates/templates-images/mobile-dark.PNG',
      },
      {
        icon: <InsightsIcon />,
        title: 'Insights Engine',
        description:
          'Transform your data into powerful insights with our advanced analysis tool, turning complex feedback into clear, actionable intelligence that drives smarter decisions.',
        imageLight: '../images/topic.png',
        imageDark: '/static/images/templates/templates-images/mobile-dark.PNG',
      },
      {
        icon: <DevicesRoundedIcon />,
        title: 'Report Generation',
        description:
          'Instantly unlock the value of customer feedback with our dynamic reports, delivering sharp, actionable summaries that empower leaders to make impactful decisions fast.',
        imageLight: '../images/report_landing.png',
        imageDark: '/static/images/templates/templates-images/devices-dark.PNG',
      },
    ],
  },
  de: {
    sectionTitle: 'Produkteigenschaften',
    items: [
      {
        icon: <DashboardIcon />,
        title: 'Dashboard',
        description:
          'Entfesseln Sie leistungsstarke Einblicke mit unserem erweiterten Dashboard, das Werkzeuge wie gestapelte Balkendiagramme, NPS-Gleitdurchschnitte und detaillierte Kommentaranalysen für klare, umsetzbare Entscheidungen bietet.',
        imageLight: '../images/dashboard_landing.png',
        imageDark: '/static/images/templates/templates-images/mobile-dark.PNG',
      },
      {
        icon: <InsightsIcon />,
        title: 'Insights Engine',
        description:
          'Verwandeln Sie Ihre Daten in wertvolle Erkenntnisse mit unserem fortschrittlichen Analysetool, das komplexes Feedback in klare, umsetzbare Intelligenz umwandelt und so klügere Entscheidungen ermöglicht.',
        imageLight: '../images/topic.png',
        imageDark: '/static/images/templates/templates-images/mobile-dark.PNG',
      },
      {
        icon: <DevicesRoundedIcon />,
        title: 'Berichtsgenerierung',
        description:
          'Schalten Sie sofort den Wert von Kundenfeedback mit unseren dynamischen Berichten frei, die prägnante, umsetzbare Zusammenfassungen liefern und Führungskräften ermöglichen, schnell wirkungsvolle Entscheidungen zu treffen.',
        imageLight: '../images/report_landing.png',
        imageDark: '/static/images/templates/templates-images/devices-dark.PNG',
      },
    ],
  },
};

export default function Features({ language }) {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const items = content[language].items;

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container
      id="features"
      sx={{
        mt: 8,
        mb: 8,
        py: { xs: 4, sm: 8 },
        bgcolor: 'background.paper',
        color: 'text.primary',
        borderRadius: '15px',
        boxShadow: 3,
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        textAlign="center"
        sx={{ mb: 4, fontWeight: 'bold' }}
      >
        {content[language].sectionTitle}
      </Typography>
      
      {/* Clickable Items */}
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
        {items.map(({ icon, title }, index) => (
          <Grid item key={index}>
            <Chip
              icon={icon}
              label={title}
              onClick={() => handleItemClick(index)}
              sx={{
                fontSize: '1.1rem',
                cursor: 'pointer',
                backgroundColor: selectedItemIndex === index ? 'primary.main' : 'grey.300',
                color: selectedItemIndex === index ? '#fff' : 'text.primary',
                boxShadow: selectedItemIndex === index ? 2 : 0,
                transition: 'background-color 0.3s',
              }}
            />
          </Grid>
        ))}
      </Grid>
      
      {/* Text and Images Section */}
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'center', fontSize: 20 }}>
              {selectedFeature.description}
            </Typography>    
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              border: '1px solid #ccc',
              boxShadow: 3,
              overflow: 'hidden',
            }}
          >
            <img
              src={language === 'en' ? selectedFeature.imageLight : selectedFeature.imageDark}
              alt={selectedFeature.title}
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
