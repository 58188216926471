import React, { useState, useEffect } from 'react';
import {
  Button, TextField, Typography, Grid, CircularProgress, Select,
  MenuItem, InputLabel, FormControl, Snackbar, Switch, FormControlLabel,
  Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody,
  TableCell, TableHead, TableRow, Box, Divider, Alert, AlertTitle
} from '@mui/material';
import axios from 'axios';

function DataPreview({ open, onCancel, onAccept, data, mappings }) {
  return (
    <Dialog fullScreen={false} open={open} fullWidth maxWidth="md">
      <DialogTitle>Data Preview</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(mappings).map((column, index) => (
                <TableCell key={index}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    {column}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(0, 5).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {Object.values(mappings).map((column, cellIndex) => (
                  <TableCell key={cellIndex}>{row[column]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onAccept} color="primary" variant="contained">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function SnowflakePopup(props) {
  const [connectionDetails, setConnectionDetails] = useState({
    account: '',
    username: '',
    password: '',
    warehouse: '',
    database: '',
    schema: '',
  });

  const [pullConfig, setPullConfig] = useState({
    table: '',
    pullType: 'latest', // 'latest' or 'unpulled'
    latestCount: 100, // Default value for latest X records
  });

  const [columnMap, setColumnMap] = useState({});
  const [previewData, setPreviewData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);

  const handleConnectionDetailChange = (event) => {
    setConnectionDetails({
      ...connectionDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handlePullConfigChange = (event) => {
    const { name, value } = event.target;
    setPullConfig(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePullOnlyNewChange = (event) => {
    setPullConfig({
      ...pullConfig,
      pullOnlyNew: event.target.checked,
    });
  };

  const handleColumnMapChange = (feature, column) => {
    setColumnMap(prev => ({ ...prev, [feature]: column }));
  };

  const validateConfig = () => {
    const requiredFields = [...Object.keys(connectionDetails), 'table'];
    for (let field of requiredFields) {
      if (!connectionDetails[field] && !pullConfig[field]) {
        setError(`Please fill in the ${field} field.`);
        return false;
      }
    }
    return true;
  };

  const handleContinue = async () => {
    if (validateConfig()) {
      setIsLoading(true);
      try {
        const response = await axios.post('/api/dev/snowflake/preview', { ...connectionDetails, ...pullConfig });
        setPreviewData(response.data);
        setStep(2);
      } catch (error) {
        setError(error.response?.data?.error || 'Failed to fetch preview data');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePreviewAccept = () => {
    setPreviewOpen(false);
    setStep(3);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/dev/snowflake/pull', { ...connectionDetails, ...pullConfig, columnMap });
      props.onDataPulled(response.data.filename);
      props.onClose();
    } catch (error) {
      setError(error.response?.data?.error || 'Data pull failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog fullScreen={false} onClose={props.onClose} open={props.open} fullWidth maxWidth="md">
      <DialogTitle>Snowflake Data Puller</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        {step === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Connection Details</Typography>
            </Grid>
            {Object.keys(connectionDetails).map((key) => (
              <Grid item xs={6} key={key}>
                <TextField
                  fullWidth
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  name={key}
                  value={connectionDetails[key]}
                  onChange={handleConnectionDetailChange}
                  type={key === 'password' ? 'password' : 'text'}
                  variant="outlined"
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Data Pull Configuration</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Table</InputLabel>
                <Select
                  name="table"
                  value={pullConfig.table}
                  onChange={handlePullConfigChange}
                  label="Table"
                >
                  <MenuItem value="customer_feedback">Customer Feedback</MenuItem>
                  {/* Add other tables as needed */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Pull Type</InputLabel>
                <Select
                  name="pullType"
                  value={pullConfig.pullType}
                  onChange={handlePullConfigChange}
                  label="Pull Type"
                >
                  <MenuItem value="latest">Latest Records</MenuItem>
                  <MenuItem value="unpulled">Unpulled Records</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {pullConfig.pullType === 'latest' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Number of Latest Records"
                  name="latestCount"
                  type="number"
                  value={pullConfig.latestCount}
                  onChange={handlePullConfigChange}
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>
        )}

        {step === 2 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Map Columns</Typography>
            </Grid>
            {Object.keys(previewData[0] || {}).map((column) => (
              <Grid item xs={6} key={column}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{column}</InputLabel>
                  <Select
                    value={Object.keys(columnMap).find(key => columnMap[key] === column) || ''}
                    onChange={(e) => handleColumnMapChange(e.target.value, column)}
                    label={column}
                  >
                    {Object.keys(previewData[0] || {}).map((feature) => (
                      <MenuItem key={feature} value={feature}>{feature}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}
          </Grid>
        )}

        {step === 3 && (
          <Typography variant="body1">
            Your data is ready to be uploaded. Click "Upload" to proceed or "Cancel" to go back.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        {step === 1 && (
          <Button onClick={handleContinue} color="primary" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Continue'}
          </Button>
        )}
        {step === 2 && (
          <Button onClick={() => setPreviewOpen(true)} color="primary" variant="contained">
            Preview
          </Button>
        )}
        {step === 3 && (
          <Button onClick={handleUpload} color="primary" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
        )}
      </DialogActions>

      <DataPreview
        open={previewOpen}
        onCancel={() => setPreviewOpen(false)}
        onAccept={handlePreviewAccept}
        data={previewData}
        mappings={columnMap}
      />
    </Dialog>
  );
}
