import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

function NavigationBarLogin() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        padding: { xs: '8px 16px', md: '16px 32px' }, // Add some padding for the app bar
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between', // Distribute space between logo and buttons
          width: '100%',
          maxWidth: '1200px', // Restrict the maximum width
          margin: '0 auto', // Center content horizontally
        }}
      >
        {/* Logo Section */}
        <Box 
          sx={{ display: 'flex', alignItems: 'center', mr: 2, cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
            <Typography variant="h3" sx={{ m: 0, p: 0, color: 'primary.main' }}>
              Wayu
            </Typography>
        </Box>

        {/* Buttons Section */}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button variant="contained" onClick={() => navigate('/login')}>
            Sign in
          </Button>
          <Button variant="outlined" onClick={() => navigate('/register')}>
            Sign up
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBarLogin;
