import React from 'react';
import { Box } from '@mui/material';

export function SentimentBar({ sentiment }) {
  if (!sentiment) return null;

  // Calculate total sentiment value
  const total = sentiment.positive + sentiment.neutral + sentiment.negative;

  // Calculate percentages
  const positivePercentage = (sentiment.positive / total) * 100;
  const neutralPercentage = (sentiment.neutral / total) * 100;
  const negativePercentage = (sentiment.negative / total) * 100;

  return (
    <Box sx={{ width: '100%', height: 8, bgcolor: 'grey.200', borderRadius: 4, overflow: 'hidden', display: 'flex' }}>
      <Box sx={{ width: `${positivePercentage}%`, height: '100%', bgcolor: 'success.main' }} />
      <Box sx={{ width: `${neutralPercentage}%`, height: '100%', bgcolor: 'grey.400' }} />
      <Box sx={{ width: `${negativePercentage}%`, height: '100%', bgcolor: 'error.main' }} />
    </Box>
  );
}