import * as React from 'react';
import { Box, Button, Container, Grid, Stack, TextField, Typography, Alert } from '@mui/material';
import axios from 'axios';

export default function Hero({ language }) {
  const content = {
    en: {
      title: 'Wayu',
      subtitle: 'We are your understanding!',
      description: 'Unlock customer insights with AI-powered feedback analysis.',
      buttonText: 'Get Access Now!',
      signupButtonText: 'Sign Up',
      successMessage: 'Access request sent successfully!',
      signupSuccessMessage: 'Signup request sent successfully!',
      errorMessage: 'There was an error sending your request.',
      emailError: 'Please enter a valid email address.',
    },
    de: {
      title: 'Wayu',
      subtitle: 'We are your understanding!',
      description: 'Entdecken Sie Kunden-Insights mit KI-gestützter Feedback-Analyse.',
      buttonText: 'Jetzt Zugang erhalten!',
      signupButtonText: 'Anmelden',
      successMessage: 'Zugriffsanfrage erfolgreich gesendet!',
      signupSuccessMessage: 'Zugriffsanfrage erfolgreich gesendet!',
      errorMessage: 'Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten.',
      emailError: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    },
  };

  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSignup = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage(content[language].emailError);
      return;
    }

    try {
      const response = await axios.post('/api/dev/signup', { email });
      if (response.data.success) {
        setSuccessMessage(content[language].signupSuccessMessage);
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.error || content[language].errorMessage);
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage(content[language].errorMessage);
      setSuccessMessage('');
      console.error("Error during signup:", error);
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          pt: { xs: 0, sm: 0 },
          pb: { xs: 0, sm: 0 },
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6} sx={{ mb: 4, mt: 4, py: 4, textAlign: { xs: 'center', md: 'left' } }}>
            <Stack spacing={2}>
              <Typography 
                component="h1" 
                variant="h1" 
                sx={{ 
                  fontSize: { xs: "3rem", sm: "4rem", md: "5rem" }, 
                  fontWeight: 1000,
                }}
              >
                <span style={{ color: 'grey' }}>W</span>e <span style={{ color: 'grey' }}>A</span>re <span style={{ color: 'grey' }}>Y</span>our <span style={{ color: 'grey' }}>U</span>nderstanding
              </Typography>
              <Typography variant="h4" color="text.secondary" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                {content[language].description}
              </Typography>
              {errorMessage && (
                <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                  {errorMessage}
                </Alert>
              )}
              {successMessage && (
                <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
                  {successMessage}
                </Alert>
              )}
              <TextField
                label="Enter your email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                sx={{ mt: 2, width: '350px' }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignup}
                sx={{ mt: 2, width: '170px', alignItems: 'center' }}
              >
                {content[language].buttonText}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                height: '800px',
                width: '100%',
                backgroundColor: 'transparent',
                padding: '0px',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  backgroundImage: "url(/images/hero_insights.drawio.png)",
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
