import React, { useState, useEffect, useContext } from 'react';
import { Container, Grid, Typography, Box, Paper, Button, Stack, Chip } from '@mui/material';
import axios from 'axios';
import { Options } from './Options';
import { DateRangeContext } from './DateRangeContext';
import TopicExplorer from './TopicExplorer';
import { SentimentBar } from './helpers/SentimentBar';

export default function Insights() {
  const { dateRange, selectedColumns, selectedFilters } = useContext(DateRangeContext);
  const [topics, setTopics] = useState([]);
  const [activeTopic, setActiveTopic] = useState(null);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get('/api/dev/data/topics', {
          params: {
            begin: dateRange[0].toISOString(),
            end: dateRange[1].toISOString(),
            selectedFilters,
            selectedColumns,
            n: 10
          },
        });

        const topicObjects = response.data["Topic Objects"];
        console.log("Topic Objects:", topicObjects);

        if (!topicObjects || topicObjects.length === 0) {
          setTopics([]);
          setActiveTopic(null);
        } else {
          const processedTopics = topicObjects.map(processTopic);
          setTopics(processedTopics);
          setActiveTopic(processedTopics[0]);
        }
      } catch (error) {
        console.error('Error fetching topics:', error);
        setTopics([]);
        setActiveTopic(null);
      }
    };

    fetchTopics();
  }, [dateRange, selectedColumns, selectedFilters]);

  return (
    <Container maxWidth="xl" sx={{ 
      height: 'calc(100vh - 64px)', 
      display: 'flex', 
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <Grid container sx={{ 
        height: '80px', 
        mb: 2, 
        mt: 2 
      }} alignItems="center">
        <Grid item xs>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Insights
          </Typography>
        </Grid>
        <Grid item>
          <Options />
        </Grid>
      </Grid>

      {topics.length === 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 350 }}>
              <Typography variant="h6" gutterBottom>Topic Overview</Typography>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>No topic data available</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 350 }}>
              <Typography variant="h6" gutterBottom>Subtopic Details</Typography>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>No subtopic data available</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight: 200 }}>
              <Typography variant="h6" gutterBottom>Areas for Improvement</Typography>
              <Box sx={{ py: 2 }}>
                <Typography>No improvement data available</Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          gap: 3, 
          flexGrow: 1,
          overflow: 'hidden',
          height: 'calc(100vh - 164px)'
        }}>
          <TopicSelector 
            topics={topics} 
            activeTopic={activeTopic} 
            setActiveTopic={setActiveTopic} 
          />
          <Box sx={{ 
            flexGrow: 1,
            overflow: 'hidden'
          }}>
            <TopicExplorer topic={activeTopic} />
          </Box>
        </Box>
      )}
    </Container>
  );
}

function TopicSelector({ topics, activeTopic, setActiveTopic }) {
  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      width: '250px',
      bgcolor: '#f8f9fa',
      p: 2,
      borderRadius: 2,
      height: '100%',
      overflow: 'hidden'
    }}>
      <Typography variant="h6" fontWeight="500" sx={{ mb: 2 }}>
        Topics
      </Typography>
      <Box sx={{ 
        overflow: 'auto',
        flexGrow: 1,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.1)',
          borderRadius: '4px',
        },
      }}>
        <Stack spacing={1}>
          {topics.map((topic, index) => (
            <Box key={index}>
              <Button
                onClick={() => setActiveTopic(topic)}
                sx={{
                  p: 1.5,
                  width: '100%',
                  borderRadius: 2,
                  bgcolor: activeTopic === topic ? 'primary.main' : 'white',
                  color: activeTopic === topic ? 'primary.contrastText' : 'text.primary',
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  '&:hover': {
                    bgcolor: activeTopic === topic ? 'primary.dark' : 'rgba(255,255,255,0.9)',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
                  },
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Typography variant="body1" noWrap>
                    {topic.name}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <SentimentBar 
                      sentiment={{
                        positive: topic.subtopics.reduce((sum, st) => sum + st.sentiment.positive, 0),
                        neutral: topic.subtopics.reduce((sum, st) => sum + st.sentiment.neutral, 0),
                        negative: topic.subtopics.reduce((sum, st) => sum + st.sentiment.negative, 0),
                      }} 
                    />
                  </Box>
                </Box>
              </Button>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

function processTopic(topic) {
  return {
    ...topic,
    subtopics: topic.subtopics.map(subtopic => ({
      ...subtopic,
      sentiment: {
        positive: subtopic.details.positive,
        neutral: subtopic.details.neutral,
        negative: subtopic.details.negative
      },
      comments: subtopic.comments.map((comment, index) => ({
        text: comment,
        sentiment: subtopic.sentiments[index]
      })),
      score: subtopic.details.nps,
      areas_of_improvement: subtopic.areas_of_improvement
    }))
  };
}
