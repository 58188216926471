import * as React from 'react';
import { useState, useCallback, useEffect, useContext, useRef } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@emotion/react';
import {
  ThemeProvider, Paper, Button, Typography, List, ListItem,
  ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton,
  Radio, Box, Alert, AlertTitle, Divider, Card, CardContent, CardActionArea, Chip
} from '@mui/material';
import Title from './Title';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import StorageIcon from '@mui/icons-material/Storage';
import DatabaseIcon from '@mui/icons-material/Storage';
import Papa from 'papaparse';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import AlertDialog from './AlertDialog';
import { DateRangeContext } from './DateRangeContext';
import { SubmitContext } from './SubmitContext';
import CircularProgressWithLabel from './helpers/CircularProgressWithLabel'
import { Options } from './Options';
import { FilePopup } from './UploadFilePopup';
import { MapsPopup } from './UploadMapsPopup';
import DownloadCsvButton from './Download';
import { SnowflakePopup } from './UploadSnowFlakePopup';

export default function Upload() {

  const { isSubmitted, triggerSubmit } = useContext(SubmitContext);
  const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [tableState, setTableState] = useState(false);
  const [columnNames, setColumnNames] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFilesTokens, setUploadedFilesTokens] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState('');
  const [uploadStatus, setUploadStatus] = useState({});
  const [uploadProgress, setUploadProgress] = useState({})
  const [alertOpen, setAlertOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentFile, setCurrentFile] = useState('');
  const { dateRange } = useContext(DateRangeContext);
  const [columnToCategoryMap, setColumnToCategoryMap] = useState({});
  const newCategoryOptions = ["ID", "Country", "Date", "Comment", "NPS", "None"];
  const featureOptions = ["ID", "Country", "Date", "Comment", "NPS"];
  const excludedOptions = ["None"];
  const [error, setError] = useState(null);
  const [cost, setCost] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [mapsDialogOpen, setMapsDialogOpen] = useState(false);
  const [activeProject, setActiveProject] = useState(true);
  const [projects, setProjects] = useState([]);
  const [preprocessingInfo, setPreprocessingInfo] = useState(null);
  const [snowflakeDialogOpen, setSnowflakeDialogOpen] = useState(false);

  const fileInputRef = useRef(null);

  const handleCSVUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onDrop([file]);
    }
  };

  const showConfirmation = (action, fileName) => {
    setCurrentAction(action);
    setCurrentFile(fileName);
    setAlertOpen(true);
  };

  const handleAgree = () => {
    if (currentAction === 'delete') {
      handleRemoveFile(currentFile);
    } else if (currentAction === 'activate') {
      handleRadioChange(currentFile);
    }
    setAlertOpen(false);
  };

  const handleDisagree = () => {
    setAlertOpen(false);
  };



  useEffect(() => {
    Promise.all([fetchUploadedFiles()]).then(() => {
    }).catch(console.error);
  }, [dateRange]);

  useEffect(() => {
    if (uploadedFiles) {
      console.log("Uploaded Files");
      console.log(uploadedFiles);
      uploadedFiles.forEach(file => {
        startStatusPolling(file.name, file.token);
      });
    }
  }, [uploadedFiles, dateRange]);


  useEffect(() => {
    axios.get('/api/dev/projects/active')
        .then(response => {
            console.log("ACTIVE_PROJECTS:")
            console.log(response);
            if (response.data == ''){
              setActiveProject(false);
            }
              

        })
        .catch(error => {
            console.error('Error:', error);
        });
    axios.get('/api/dev/projects')
          .then(response => {
              console.log("PROJECTS:");
              console.log(response);
              setProjects(response.data);
          })
          .catch(error => {
              console.error('Error:', error);
          });
}, [])


  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file.name.toLowerCase().endsWith('.csv')) {
      setError({ title: "Invalid File Format", message: "Please upload a .CSV file." });
      return;
    }

    setSelectedFile(file);
    setFileName(file.name);
    setFileDialogOpen(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (results.data.length > 0) {
          setColumnNames(Object.keys(results.data[0]));
          setParsedData(results.data);
          setCost(results.data.length);
        } else {
          setError({ title: "Empty File", message: "The CSV file is empty or could not be parsed correctly." });
        }
      },
      error: (error) => {
        setError({ title: "Parsing Error", message: `Error parsing CSV: ${error.message}` });
      }
    });
  }, []);

  const getAvailableOptions = (currentColumnName) => {
    const selectedCategories = Object.values(columnToCategoryMap);

    return newCategoryOptions.filter(option =>
      excludedOptions.includes(option) ||
      !selectedCategories.includes(option) ||
      columnToCategoryMap[currentColumnName] === option
    );
  };

  const setParentFeatureMap = (m) => {
    const updatedMap = {};
    columnNames.map((x) => { updatedMap[x] = 'None' });
    Object.entries(m).map(([k, v]) => { updatedMap[v] = k });
    setColumnToCategoryMap(updatedMap);
    console.log(updatedMap);
  }

  const handleCategoryChange = (columnName, newCategory) => {
    setColumnToCategoryMap(prevMap => {
      const updatedMap = { ...prevMap, [columnName]: newCategory };
      return updatedMap;
    });
  };

  const validateCategorySelections = () => {
    const necessaryCategories = newCategoryOptions.filter(option => !excludedOptions.includes(option));
    const selectedCategories = Object.values(columnToCategoryMap);
    const allNecessarySelected = necessaryCategories.every(category => selectedCategories.includes(category));

    return allNecessarySelected;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv', noClick: true });

  const handleFileUpload = () => {
    setError(null);
    setFileDialogOpen(false);

    if (isUploading) {
      return;
    }
    setIsUploading(true);

    if (!selectedFile) {
      setError({ title: "No File Selected", message: "Please select a file before uploading." });
      setIsUploading(false);
      return;
    }

    setUploadStatus(prevStatus => ({ ...prevStatus, [selectedFile.name]: 'uploading' }));

    const allCategoriesUsed = validateCategorySelections();

    if (!allCategoriesUsed) {
      setError({ title: "Invalid Category Mapping", message: "Not all category selections have been used. Please ensure each category is selected at least once." });
      setIsUploading(false);
      return;
    }

    const transformedData = parsedData.map(row => {
      const transformedRow = {};
      Object.keys(row).forEach(originalKey => {
        const newKey = columnToCategoryMap[originalKey] || originalKey;
        transformedRow[newKey] = row[originalKey];
      });
      return transformedRow;
    });

    if (transformedData.length > 0) {
      axios.post('/api/dev/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          setUploadedFiles(prevFiles => [...prevFiles, { name: fileName, token: response.data.file_token }]);
          startStatusPolling(fileName, response.data.file_token);
          setTableState(false);
          triggerSubmit();
          setError(null);
          setIsUploading(false);
          setPreprocessingInfo(response.data.preprocessing_info);
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || "An unknown error occurred during file upload.";
          setError({ title: "Upload Error", message: errorMessage });
          setIsUploading(false);
        });
    }
  };
  const startStatusPolling = (fileName, fileToken) => {
    const pollStatus = () => {
      axios.get('/api/dev/getUploadStatus', {
        params: { filename: fileName, file_token: fileToken }
      })
        .then(response => {
          console.log("FILENAME");
          console.log(fileName);
          const status = response.data.status;
          const progress = response.data.progress;
          console.log("PROGRESS")
          console.log(progress)
          setUploadStatus(prevStatus => ({ ...prevStatus, [fileName]: status }));
          setUploadProgress(prevStatus => ({ ...prevStatus, [fileName]: progress }))

          if (status === 'completed' || status === 'error' || status === 'unknown') {
            clearInterval(intervalId);
          }
        })
        .catch(error => {
          console.error('Error:', error);
          clearInterval(intervalId);
        });
    };

    const intervalId = setInterval(pollStatus, 5000);
    pollStatus();
  };

  const fetchUploadedFiles = () => {
    axios.get('/api/dev/getUploadedFiles')
      .then(response => {
        console.log(response)
        const files = response.data.uploadedFiles;
        const tokens = response.data.file_tokens;
        const combinedFiles = files.map((file, index) => ({ name: file, token: tokens[index] }));
        setUploadedFiles(combinedFiles);
      })
      .catch(error => console.error('Error:', error));
  };

  const handleRemoveFile = (fileName) => {
    const fileToken = uploadedFiles.find(file => file.name === fileName).token;
    setUploadStatus(prevStatus => ({ ...prevStatus, [fileName]: 'deleting' }));
    const updatedFiles = uploadedFiles.filter(file => file.name !== fileName);

    axios.post('/api/dev/updateUploadedFiles', {
      filename: fileName,
      file_token: fileToken
    })
      .then(response => {
        console.log("Updated files list sent to backend:", response);
        setUploadedFiles(updatedFiles);
      })
      .catch(error => console.error('Error:', error));
  };

  const handleFileUploadCancel = () => {
    setFileDialogOpen(false);
    setFileName('');
    setParsedData([]);
    setSelectedFile(null);
    setCost(0);
  }

  const fetchGoogleReviews = (newIntegrationName, searchString) => {

    console.log("Started fetching reviews")

    setMapsDialogOpen(false);

    if (isUploading) {
      console.log("Already uploading")
      return;
    }
    setIsUploading(true);

    setUploadStatus(prevStatus => ({ ...prevStatus, [newIntegrationName]: 'uploading' }));

    if (uploadedFiles.map(file => file.name).includes(newIntegrationName)) {
      setError({ title: "Duplicate File", message: "This file has already been uploaded." });
      console.log("Error, already integrated")
      setIsUploading(false);
      return;
    }

    axios.post('/api/dev/upload/googleReviews', {
      filename: newIntegrationName,
      info: searchString
    })
      .then((response) => {
        setUploadedFiles(prevFiles => [...prevFiles, { name: newIntegrationName, token: response.data.file_token }]);
        startStatusPolling(newIntegrationName, response.data.file_token);
        triggerSubmit();
        setIsUploading(false);
      })
      .catch(error => {
        const errorMessage = error.response?.data?.error || "An unknown error occurred during Google Reviews fetch.";
        setError({ title: "Fetch Error", message: errorMessage });
        setIsUploading(false);
      });
  }

  const dropzoneStyle = {
    border: '2px dashed #ccc',
    borderRadius: '10px',
    marginTop: '10px',
    minHeight: '170px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isDragActive ? '#eeeeee' : '#fafafa',
    color: isDragActive ? '#2196f3' : 'black',
  };

  const getPreprocessingAlertSeverity = (info) => {
    const removedPercentage = (info.initial_count - info.final_count) / info.initial_count * 100;
    if (removedPercentage > 50) return "error";
    if (removedPercentage > 20) return "warning";
    return "info";
  };

  const handleSnowflakeDataPulled = (filename) => {
    setUploadedFiles(prevFiles => [...prevFiles, { name: filename, token: 'snowflake' }]);
    triggerSubmit();
  };

  const apiOptions = [
    { 
      name: 'CSV Upload', 
      icon: 'https://cdn.jsdelivr.net/npm/simple-icons@v5/icons/microsoftexcel.svg',
      available: true, 
      action: handleCSVUpload
    },
    { 
      name: 'Google Maps', 
      icon: 'https://cdn.jsdelivr.net/npm/simple-icons@v5/icons/googlemaps.svg',
      available: true, 
      action: () => setMapsDialogOpen(true) 
    },
    { 
      name: 'Snowflake', 
      icon: 'https://cdn.jsdelivr.net/npm/simple-icons@v5/icons/snowflake.svg',
      available: false, 
    // action: () => setSnowflakeDialogOpen(true) 
    },
    { 
      name: 'Amazon Reviews', 
      icon: 'https://cdn.jsdelivr.net/npm/simple-icons@v5/icons/amazon.svg',
      available: false 
    },
    { 
      name: 'Booking.com', 
      icon: 'https://static.cdnlogo.com/logos/b/46/booking-com.svg',
      available: false 
    },
    { 
      name: 'Trustpilot', 
      icon: 'https://cdn.jsdelivr.net/npm/simple-icons@v5/icons/trustpilot.svg',
      available: false 
    },
    { 
      name: 'PostgreSQL', 
      icon: 'https://cdn.jsdelivr.net/npm/simple-icons@v5/icons/postgresql.svg',
      available: false 
    },
    { 
      name: 'MongoDB', 
      icon: 'https://cdn.jsdelivr.net/npm/simple-icons@v5/icons/mongodb.svg',
      available: false 
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ }}>
        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
            <AlertTitle>{error.title}</AlertTitle>
            {error.message}
          </Alert>
        )}
        {preprocessingInfo && (
          <Alert 
            severity={getPreprocessingAlertSeverity(preprocessingInfo)} 
            onClose={() => setPreprocessingInfo(null)} 
            sx={{ mb: 2 }}
          >
            <AlertTitle>Data Preprocessing Results</AlertTitle>
            <p>Initial row count: {preprocessingInfo.initial_count}</p>
            <p>Final row count: {preprocessingInfo.final_count}</p>
            <p>Rows removed:</p>
            <ul>
              <li>Duplicate rows: {preprocessingInfo.duplicate_rows_removed}</li>
              <li>Rows with invalid NPS: {preprocessingInfo.nan_nps_rows_removed + preprocessingInfo.invalid_nps_rows_removed}</li>
              <li>Rows with invalid dates: {preprocessingInfo.invalid_date_rows_removed}</li>
              <li>Rows with empty comments: {preprocessingInfo.empty_comment_rows_removed}</li>
              <li>Duplicate comments: {preprocessingInfo.duplicate_comment_rows_removed}</li>
            </ul>
            <p>Total rows removed: {preprocessingInfo.initial_count - preprocessingInfo.final_count}</p>
          </Alert>
        )}
      <Grid container sx={{ mb: 2, mt: 2 }} alignItems="center">
        <Grid item xs>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: "5px" }}>
            Upload
          </Typography>
        </Grid>
        <Grid item>
          <Options />
        </Grid>
      </Grid>
        {!(activeProject) && (
          <Alert severity="error">Please activate or create a project before uploading!</Alert>
        )}
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Paper sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom>Data Integration Options</Typography>
            <Grid container spacing={3}>
              {apiOptions.map((api, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card 
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      opacity: !api.available && !activeProject ? 0.5 : 1,
                    }}
                  >
                    <CardActionArea
                      onClick={api.available && activeProject ? api.action : undefined}
                      disabled={!api.available || !activeProject}
                      sx={{ 
                        flexGrow: 1, 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        p: 2,
                        backgroundColor: api.name === 'CSV Upload' && isDragActive ? '#eeeeee' : 'inherit',
                      }}
                    >
                      <img 
                        src={api.icon} 
                        alt={`${api.name} icon`} 
                        style={{ width: '4rem', height: '4rem', marginBottom: '1rem' }}
                      />
                      <Typography variant="subtitle1" align="center">{api.name}</Typography>
                      {!api.available && (
                        <Chip
                          label="Coming Soon"
                          size="small"
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            backgroundColor: theme.palette.warning.light,
                          }}
                        />
                      )}
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>

          <div {...getRootProps()} style={{display: 'none'}}>
            <input {...getInputProps()} ref={fileInputRef} onChange={handleFileChange} />
          </div>
          
          <FilePopup
            open={fileDialogOpen}
            features={featureOptions}
            columns={columnNames}
            data={parsedData}
            cost={cost}
            setParentFeatureMap={(x) => setColumnToCategoryMap(x)}
            onClose={() => handleFileUpload()}
            onCancel={() => handleFileUploadCancel()}
            filename={fileName}
          />

          <MapsPopup
            open={mapsDialogOpen}
            onClose={() => setMapsDialogOpen(false)}
            onCancel={() => setMapsDialogOpen(false)}
            onFetch={fetchGoogleReviews}
          />

          <SnowflakePopup
            open={snowflakeDialogOpen}
            onClose={() => setSnowflakeDialogOpen(false)}
            onDataPulled={handleSnowflakeDataPulled}
          />

          {/* Available Datasets section */}
          <Paper sx={{ p: 4, mt: 4 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" sx={{marginBottom: '20px'}}>
              <Typography variant="h6">Available Datasets</Typography>
              {uploadedFiles.length > 0 && (
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" sx={{ mr: 2 }}>Download Analysis</Typography>
                  <DownloadCsvButton />
                </Box>
              )}
            </Box>
            <Divider sx={{ mb: 2 }} />
            <List>
              {uploadedFiles.length > 0 ? (
                uploadedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: uploadStatus[file.name] === 'error' ? 'red' : 'grey' }}>
                        {uploadStatus[file.name] !== 'completed' ? <CircularProgressWithLabel value={uploadProgress[file.name]} /> : <FolderIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={file.name} 
                      secondary={
                        uploadStatus[file.name] === 'error' 
                          ? 'Error occurred during processing, please check the data format!' 
                          : uploadStatus[file.name]
                      } 
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => showConfirmation('delete', file.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              ) : (
                <Typography>No files uploaded.</Typography>
              )}
            </List>
          </Paper>
        </Container>
      </Container>

      <AlertDialog
        open={alertOpen}
        title="Confirm Action"
        message={`Are you sure you want to ${currentAction} "${currentFile}"?`}
        onAgree={handleAgree}
        onDisagree={handleDisagree}
      />
    </ThemeProvider>
  );
}
