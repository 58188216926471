import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const logoStyle = {
  maxHeight: '35px',
};

const content = {
  en: {
    product: 'Product',
    features: 'Features',
    highlights: 'Highlights',
    pricing: 'Pricing',
    company: 'Company',
    aboutUs: 'About us',
    legal: 'Legal',
    terms: 'Terms',
    privacy: 'Privacy',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    impressum: 'Imprint',
    workInProgress: 'This site is a work in progress and subject to change.',
  },
  de: {
    product: 'Produkt',
    features: 'Eigenschaften',
    highlights: 'Highlights',
    pricing: 'Preise',
    company: 'Unternehmen',
    aboutUs: 'Über uns',
    legal: 'Rechtliches',
    terms: 'Bedingungen',
    privacy: 'Datenschutz',
    privacyPolicy: 'Datenschutzrichtlinie',
    termsOfService: 'Nutzungsbedingungen',
    impressum: 'Impressum',
    workInProgress: 'Diese Seite ist in Arbeit und kann sich ändern.',
  },
};

function Copyright({ language }) {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://mui.com/">Sitemark&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer({ language }) {
  const {
    product,
    features,
    highlights,
    pricing,
    company,
    aboutUs,
    legal,
    terms,
    privacy,
    privacyPolicy,
    termsOfService,
    impressum,
    workInProgress,
  } = content[language];

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <Typography variant="h3" sx={{ m: 0, p: 0, color: 'primary.main' }}>
              Wayu
            </Typography>
          </Box>
            <Typography variant="body2" gutterBottom>
              Leverage AI to explore and understand customer comments.
              Simplify customer feedback analysis for your teams.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 4,
          }}
        >
          {/* <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              {product}
            </Typography>
            <Link color="text.secondary" href="#features">
              {features}
            </Link>
            <Link color="text.secondary" href="#highlights">
              {highlights}
            </Link>
            <Link color="text.secondary" href="#pricing">
              {pricing}
            </Link>
          </Box> */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              {legal}
            </Typography>
            <Link color="text.secondary" href="/imprint">
              {impressum}
            </Link>
            <Link color="text.secondary" href="/tos">
              {terms}
            </Link>
            <Link color="text.secondary" href="/privacy">
              {privacy}
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="/imprint">
            {impressum}
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="/privacy">
            {privacyPolicy}
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="/tos">
            {termsOfService}
          </Link>
        </div>
      </Box>
    </Container>
  );
}
