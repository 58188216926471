import React, { useState } from 'react';
import { 
  Card, 
  CardContent,
  Typography, 
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  IconButton,
  Stack,
  Grid
} from "@mui/material";
import { 
  ThumbUp, 
  ThumbDown, 
  ChatBubbleOutline, 
  ChevronRight,
  ArrowBack,
  Topic as TopicIcon,
  SubdirectoryArrowRight,
  TrendingUp,
  Comment,
  Assessment,
  LightbulbOutlined,
  FilterList
} from '@mui/icons-material'; 
import { SentimentBar } from './helpers/SentimentBar';

function CommentList({ comments }) {
  if (!comments || comments.length === 0) return <Typography>No comments available</Typography>;

  const sentimentConfig = {
    Positive: {
      bgcolor: 'rgba(72, 218, 72, 0.1)',
      icon: <ThumbUp fontSize="small" />,
      borderColor: 'rgba(72, 218, 72, 0.6)'
    },
    Negative: {
      bgcolor: 'rgba(231, 80, 80, 0.1)',
      icon: <ThumbDown fontSize="small" />,
      borderColor: 'rgba(231, 80, 80, 0.6)'
    },
    Neutral: {
      bgcolor: 'rgba(211, 211, 211, 0.3)',
      icon: <ChatBubbleOutline fontSize="small" />,
      borderColor: 'lightgrey'
    }
  };

  return (
    <List sx={{ maxHeight: 500, overflow: 'auto' }}>
      {comments.map((comment, index) => {
        const config = sentimentConfig[comment.sentiment] || sentimentConfig.Neutral;
        return (
          <ListItem 
            key={index} 
            sx={{ 
              bgcolor: config.bgcolor,
              borderLeft: 3,
              borderColor: config.borderColor,
              borderRadius: 1, 
              mb: 1,
              '&:hover': {
                bgcolor: 'action.hover',
              }
            }}
          >
            <ListItemIcon>
              {config.icon}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.primary">
                  {comment.text}
                </Typography>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}

function TopicExplorer({ topic }) {
  const [activeView, setActiveView] = useState('overview'); // 'overview', 'subtopic', 'improvement'
  const [selectedSubtopic, setSelectedSubtopic] = useState(null);
  const [selectedImprovement, setSelectedImprovement] = useState(null);

  const handleBackClick = () => {
    setActiveView('overview');
    setSelectedSubtopic(null);
    setSelectedImprovement(null);
  };

  return (
    <Card sx={{ 
      height: '100%', 
      bgcolor: '#ffffff', 
      borderRadius: 3,
      boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <CardContent sx={{ 
        height: '100%',
        p: 3,
        '&:last-child': { pb: 3 },
        overflow: 'hidden'
      }}>
        {activeView !== 'overview' && (
          <IconButton onClick={handleBackClick} sx={{ mb: 2 }}>
            <ArrowBack />
          </IconButton>
        )}
        
        {activeView === 'overview' && (
          <TopicOverview 
            topic={topic}
            onSubtopicClick={(subtopic) => {
              setSelectedSubtopic(subtopic);
              setActiveView('subtopic');
            }}
            onImprovementClick={(improvement) => {
              setSelectedImprovement(improvement);
              setActiveView('improvement');
            }}
          />
        )}

        {activeView === 'subtopic' && selectedSubtopic && (
          <SubtopicView subtopic={selectedSubtopic} />
        )}

        {activeView === 'improvement' && selectedImprovement && (
          <ImprovementView 
            improvement={selectedImprovement}
            subtopics={topic.subtopics}
          />
        )}
      </CardContent>
    </Card>
  );
}

function TopicOverview({ topic, onSubtopicClick }) {
  const totalComments = topic.subtopics.reduce((sum, st) => sum + st.details.amount, 0);
  const totalSentiments = topic.subtopics.reduce((acc, st) => ({
    positive: acc.positive + st.sentiment.positive,
    neutral: acc.neutral + st.sentiment.neutral,
    negative: acc.negative + st.sentiment.negative
  }), { positive: 0, neutral: 0, negative: 0 });
  
  const npsScore = topic.subtopics.reduce((sum, st) => sum + st.score, 0) / topic.subtopics.length;
  
  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <Box sx={{ flexShrink: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TopicIcon sx={{ mr: 2, color: 'primary.main' }} />
            <Typography variant="h5" fontWeight="600">
              {topic.name}
            </Typography>
          </Box>
          <Typography variant="h5" fontWeight="600" color="info.main">
            NPS: {npsScore.toFixed(1)}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Chip
              icon={<Comment />}
              label={`${totalComments} Comments`}
              variant="outlined"
              color="primary"
            />
            <Chip
              icon={<FilterList />}
              label={`${topic.subtopics.length} Subtopics`}
              variant="outlined"
              color="secondary"
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Chip
              icon={<ThumbUp fontSize="small" />}
              label={`${totalSentiments.positive} Positive`}
              variant="outlined"
              sx={{ color: 'success.main', borderColor: 'success.main' }}
            />
            <Chip
              icon={<ChatBubbleOutline fontSize="small" />}
              label={`${totalSentiments.neutral} Neutral`}
              variant="outlined"
              sx={{ color: 'text.secondary', borderColor: 'text.secondary' }}
            />
            <Chip
              icon={<ThumbDown fontSize="small" />}
              label={`${totalSentiments.negative} Negative`}
              variant="outlined"
              sx={{ color: 'error.main', borderColor: 'error.main' }}
            />
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        overflow: 'hidden'
      }}>
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#f8f9fa',
          borderRadius: 2,
          p: 2,
          overflow: 'hidden'
        }}>
          <Box sx={{ flexShrink: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Assessment sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6" fontWeight="500">
                Subtopics
              </Typography>
            </Box>
          </Box>
          
          <Box sx={{ 
            overflow: 'auto',
            flex: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.1)',
              borderRadius: '4px',
            },
          }}>
            <Stack spacing={1}>
              {topic.subtopics.map((subtopic, index) => (
                <Button
                  key={index}
                  onClick={() => onSubtopicClick(subtopic)}
                  sx={{
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'white',
                    color: 'text.primary',
                    justifyContent: 'space-between',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    '&:hover': {
                      bgcolor: 'rgba(255,255,255,0.9)',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
                      transform: 'translateY(-1px)',
                      transition: 'all 0.2s'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SubdirectoryArrowRight sx={{ mr: 1, color: 'primary.main' }} />
                    <Box>
                      <Typography variant="body1">{subtopic.name}</Typography>
                      <Box sx={{ mt: 1, width: '150px' }}>
                        <SentimentBar sentiment={subtopic.sentiment} />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Chip 
                      label={`${subtopic.details.amount} comments`}
                      size="small"
                      sx={{ bgcolor: '#e9ecef' }}
                    />
                    <ChevronRight />
                  </Box>
                </Button>
              ))}
            </Stack>
          </Box>
        </Box>

        <Box sx={{ 
          height: '35%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#f8f9fa',
          borderRadius: 2,
          p: 2,
          overflow: 'hidden'
        }}>
          <Box sx={{ flexShrink: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <LightbulbOutlined sx={{ mr: 1, color: 'warning.main' }} />
              <Typography variant="h6" fontWeight="500">
                Areas for Improvement
              </Typography>
            </Box>
          </Box>
          
          <Box sx={{ 
            overflow: 'auto',
            flex: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.1)',
              borderRadius: '4px',
            },
          }}>
            <Stack spacing={1}>
              {[...new Set(topic.subtopics.flatMap(s => s.areas_of_improvement))]
                .filter(improvement => improvement.toLowerCase() !== "none")
                .map((improvement, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      bgcolor: 'white',
                      color: 'text.primary',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <TrendingUp sx={{ mr: 2, color: 'warning.main' }} />
                    <Typography variant="body1">{improvement}</Typography>
                  </Box>
                ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function SubtopicView({ subtopic }) {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Assessment sx={{ mr: 2, color: 'primary.main' }} />
        <Typography variant="h5" fontWeight="600">
          {subtopic.name}
        </Typography>
      </Box>

      <Box sx={{ 
        mb: 3, 
        p: 2, 
        bgcolor: '#f8f9fa',
        borderRadius: 2,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Assessment sx={{ mr: 1, color: 'primary.main' }} />
          <Typography variant="subtitle1">Sentiment Distribution</Typography>
        </Box>
        <SentimentBar sentiment={subtopic.sentiment} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThumbUp sx={{ mr: 0.5, fontSize: 'small', color: 'success.main' }} />
            <Typography variant="caption" color="success.main">Positive</Typography>
          </Box>
          <Typography variant="caption" color="text.secondary">Neutral</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThumbDown sx={{ mr: 0.5, fontSize: 'small', color: 'error.main' }} />
            <Typography variant="caption" color="error.main">Negative</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Comment sx={{ mr: 1, color: 'primary.main' }} />
        <Typography variant="h6">Comments</Typography>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <CommentList comments={subtopic.comments} />
      </Box>
    </Box>
  );
}

export default TopicExplorer;
